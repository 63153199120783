import { ChainId, WBNB } from '@pancakeswap/sdk'
import { EKEY, WKEY, LTKEY } from './common'

export const arbitrumTokens = {
  wbnb: WBNB[ChainId.ARBITRUM],
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: WBNB[ChainId.ARBITRUM],
  ekey: EKEY,
  wkey: WKEY,
  ltkey: LTKEY,
}
