import { Box, Button, Flex, LinkExternal, Skeleton, Text, CopyAddress } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import useAuth from 'hooks/useAuth'
import useNativeCurrency from 'hooks/useNativeCurrency'
import useOraclePrice from 'hooks/useOraclePrice'
import { ChainLogo } from 'components/Logo/ChainLogo'

import truncateHash from '@pancakeswap/utils/truncateHash'
import { getBlockExploreLink, getBlockExploreName } from 'utils'
import { formatBigNumber, getFullDisplayBalance } from '@pancakeswap/utils/formatBalance'
import { useBalance } from 'wagmi'
import {
  WalletInfoContainer,
  WalletInfoHeader,
  WalletInfoHeaderLeft,
  WalletInfoHeaderRight,
  WalletInfoHeaderIcon,
  WalletInfoTitle,
  WalletInfoValue,
  WalletInfoUsd,
} from './WalletInfoForEkeyStyles'

const COLORS = {
  ETH: '#627EEA',
  BNB: '#14151A',
}

const copyText = (text: string, cb?: () => void) => {
  if (navigator.clipboard && navigator.permissions) {
    navigator.clipboard.writeText(text).then(cb)
  } else if (document.queryCommandSupported('copy')) {
    const ele = document.createElement('textarea')
    ele.value = text
    document.body.appendChild(ele)
    ele.select()
    document.execCommand('copy')
    document.body.removeChild(ele)
    cb?.()
  }
}

const WalletInfo: React.FC = () => {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const native = useNativeCurrency()
  const nativeBalance = useBalance({ address: account, chainId })
  const nativePrice = useOraclePrice(native.symbol)
  const { logout } = useAuth()

  const handleLogout = () => {
    logout()
  }

  return (
    <WalletInfoContainer>
      <WalletInfoHeader>
        <WalletInfoHeaderLeft>{truncateHash(account)}</WalletInfoHeaderLeft>
        <WalletInfoHeaderRight>
          <WalletInfoHeaderIcon
            src="/images/ekey/copy.svg"
            alt="WalletInfoHeaderIcon"
            onClick={() => copyText(account)}
          />
          <WalletInfoHeaderIcon
            src="/images/ekey/linktochain.svg"
            alt="WalletInfoHeaderIcon"
            onClick={() => window.open(getBlockExploreLink(account, 'address', chainId))}
          />
          <WalletInfoHeaderIcon src="/images/ekey/logout.svg" alt="WalletInfoHeaderIcon" onClick={handleLogout} />
        </WalletInfoHeaderRight>
      </WalletInfoHeader>
      <WalletInfoTitle>{t('Balance')}</WalletInfoTitle>
      <WalletInfoValue>
        {!nativeBalance.isFetched ? (
          <Skeleton height="22px" width="100%" />
        ) : (
          <Text fontSize="24px" fontWeight={800}>
            {formatBigNumber(nativeBalance.data.value, 6)} {native.symbol}
          </Text>
        )}
      </WalletInfoValue>
      <WalletInfoUsd>
        $
        {!nativeBalance.isFetched
          ? '-'
          : (Number(formatBigNumber(nativeBalance.data.value, 6)) * nativePrice).toFixed(2)}{' '}
        USD
      </WalletInfoUsd>
      {/* <CopyAddress tooltipMessage={t('Copied')} account={account} /> */}
      <Button style={{ marginTop: '24px' }} variant="primary" width="100%" minHeight={48}>
        {t('View and sell NFTs')}
      </Button>
      {/* <Button style={{marginTop: '24px'}} variant="secondary" width="100%" minHeight={48}>
        {t('Disconnect Wallet')}
      </Button> */}
    </WalletInfoContainer>
  )
}

export default WalletInfo
