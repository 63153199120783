import useSWR from 'swr'
import { ChainId } from '@pancakeswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import { formatBigNumberToFixed } from '@pancakeswap/utils/formatBalance'
import { multicallv2 } from 'utils/multicall'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import { useActiveChainId } from './useActiveChainId'

function getOracleAddress(chainId: number, symbol: string): string | undefined {
  switch (chainId) {
    case ChainId.ARBITRUM:
      return {
        ETH: '0x639fe6ab55c921f74e7fac1ee960c0b6293ba612',
        WETH: '0x639fe6ab55c921f74e7fac1ee960c0b6293ba612',
        BNB: '0x6970460aabf80c5be983c6b74e5d06dedca95d4a',
        WBNB: '0x6970460aabf80c5be983c6b74e5d06dedca95d4a',
      }[symbol.toUpperCase()]
    case ChainId.BSC:
      return {
        ETH: '0x9ef1b8c0e4f7dc8bf5719ea496883dc6401d5b2e',
        WETH: '0x9ef1b8c0e4f7dc8bf5719ea496883dc6401d5b2e',
        BNB: '0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee',
        WBNB: '0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee',
      }[symbol.toUpperCase()]
    default:
      return undefined
  }
}

const useOraclePrice = (symbol: string) => {
  const { chainId } = useActiveChainId()
  const oracleAddress = getOracleAddress(chainId, symbol)
  const { data: price } = useSWR(chainId && oracleAddress && ['useOraclePrice', oracleAddress], async () => {
    if (!oracleAddress) return 0
    const [[decimals], [latestAnswer]] = await multicallv2<[[number], [BigNumber]]>({
      chainId,
      abi: chainlinkOracleAbi,
      calls: [
        { address: oracleAddress, name: 'decimals' },
        { address: oracleAddress, name: 'latestAnswer' },
      ],
    })
    return Number(formatBigNumberToFixed(latestAnswer, 2, decimals))
  })
  return price
}

export default useOraclePrice
