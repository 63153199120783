import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Ether Key',
  defaultTitle: 'Ether Key',
  description:
    'LSDFi, GambleFi, DEX, Derivatives, and Lending are five real-yield ecosystems that maximize returns for $EKEY holders.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@Ekey',
    site: '@Ekey',
  },
  openGraph: {
    title: 'Ether Key - A next evolution DeFi for Ethereum 2.0',
    description:
      'LSDFi, GambleFi, DEX, Derivatives, and Lending are five real-yield ecosystems that maximize returns for $EKEY holders.',
    images: [{ url: '/images/ekey/lottie-header-bg.png' }],
  },
}
