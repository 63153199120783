import { ContextApi } from '@pancakeswap/localization'

type ConfigMenuItemsType = {
  iconActive: string,
  iconDisabled: string,
  name: string,
  href?: string,
  active: boolean,
  isHot: boolean,
  isAlive: boolean
}

export const menuConfig: (
  t: ContextApi['t']
) => ConfigMenuItemsType[] = (t) => [
  {
    iconActive: '/images/ekey/prediction-active.svg',
    iconDisabled: '/images/ekey/prediction-disabled.svg',
    name: t('Prediction'),
    href: '/prediction',
    active: false,
    isHot: true,
    isAlive: false
  },
  {
    iconActive: '/images/ekey/lottery-active.svg',
    iconDisabled: '/images/ekey/lottery-disabled.svg',
    name: t('Lucky Draw'),
    href: '/lottery',
    active: false,
    isHot: true,
    isAlive: false
  },
  {
    iconActive: '/images/ekey/earn-active.svg',
    iconDisabled: '/images/ekey/earn-disabled.svg',
    name: t('Earn'),
    active: false,
    isHot: false,
    isAlive: false
  },
  {
    iconActive: '/images/ekey/games-active.svg',
    iconDisabled: '/images/ekey/games-disabled.svg',
    name: t('Games'),
    active: false,
    isHot: false,
    isAlive: false
  },
  {
    iconActive: '/images/ekey/sports-active.svg',
    iconDisabled: '/images/ekey/sports-disabled.svg',
    name: t('Sports'),
    active: false,
    isHot: false,
    isAlive: false
  },
  {
    iconActive: '/images/ekey/swap-active.svg',
    iconDisabled: '/images/ekey/swap-disabled.svg',
    name: t('Swap'),
    active: false,
    isHot: false,
    isAlive: false
  }
]
