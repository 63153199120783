import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    // <Svg viewBox="0 0 198 199" {...props}>
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M98.552 198.607c-29.49-.022-53.246-7.027-69.844-19.609C11.91 166.265 3 148.195 3 127.748c0-19.701 8.891-33.907 18.952-43.51 7.884-7.525 16.584-12.344 22.642-15.117-1.37-4.162-3.079-9.61-4.608-15.238-2.046-7.53-4.053-16.366-4.053-22.84 0-7.663 1.687-15.36 6.238-21.34C46.98 3.385 54.218 0 62.926 0c6.806 0 12.584 2.499 17.108 6.81 4.324 4.12 7.202 9.593 9.189 15.298 3.491 10.024 4.85 22.618 5.232 35.187h8.341c.382-12.569 1.741-25.163 5.233-35.187 1.987-5.705 4.865-11.177 9.189-15.298C121.741 2.5 127.519 0 134.325 0c8.708 0 15.947 3.385 20.755 9.703 4.551 5.98 6.239 13.677 6.239 21.34 0 6.474-2.007 15.31-4.054 22.84-1.529 5.628-3.238 11.076-4.608 15.238 6.058 2.773 14.759 7.592 22.643 15.118 10.06 9.602 18.952 23.808 18.952 43.509 0 20.447-8.911 38.517-25.708 51.25-16.598 12.582-40.354 19.587-69.844 19.609h-.148z"
    //     fill="#633001"
    //   />
    //   <path
    //     d="M62.926 7.288c-12.754 0-18.626 9.516-18.626 22.675 0 10.46 6.822 31.408 9.621 39.563.63 1.835-.36 3.844-2.164 4.555-10.222 4.031-40.39 18.789-40.39 52.588 0 35.603 30.658 62.448 87.191 62.49h.135c56.534-.042 87.19-26.887 87.19-62.49 0-33.799-30.167-48.557-40.389-52.588-1.804-.71-2.794-2.72-2.164-4.555 2.799-8.154 9.621-29.103 9.621-39.563 0-13.16-5.871-22.675-18.626-22.675-18.36 0-22.936 26.007-23.263 53.92-.022 1.863-1.528 3.375-3.392 3.375H89.58c-1.863 0-3.37-1.512-3.391-3.375-.326-27.913-4.903-53.92-23.263-53.92z"
    //     fill="#D1884F"
    //   />
    //   <path
    //     d="M98.693 177.755c-41.538 0-87.253-22.235-87.325-51.018v.134c0 35.632 30.705 62.491 87.325 62.491 56.62 0 87.325-26.859 87.325-62.491v-.134c-.072 28.783-45.787 51.018-87.325 51.018z"
    //     fill="#FEDC90"
    //   />
    //   <path
    //     className="eye"
    //     d="M75.614 117.896c0 9.718-4.593 14.779-10.258 14.779-5.665 0-10.258-5.061-10.258-14.779s4.593-14.779 10.258-14.779c5.665 0 10.258 5.061 10.258 14.779zM142.288 117.896c0 9.718-4.592 14.779-10.257 14.779-5.666 0-10.258-5.061-10.258-14.779s4.592-14.779 10.258-14.779c5.665 0 10.257 5.061 10.257 14.779z"
    //     fill="#633001"
    //   />
    // </Svg>
    <Svg width="60" height="60" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="10" cy="10" r="10" fill="white"/>
    <g clipPath="url(#clip0_658_7991)">
    <path d="M9.98836 6.51188C9.6741 6.51188 9.36689 6.41869 9.10559 6.24409C8.84429 6.0695 8.64063 5.82134 8.52037 5.531C8.4001 5.24065 8.36864 4.92117 8.42995 4.61294C8.49126 4.30472 8.64259 4.02159 8.86481 3.79938C9.08702 3.57716 9.37015 3.42583 9.67837 3.36452C9.9866 3.30321 10.3061 3.33467 10.5964 3.45494C10.8868 3.5752 11.1349 3.77886 11.3095 4.04016C11.4841 4.30146 11.5773 4.60867 11.5773 4.92293C11.5773 5.34435 11.4099 5.7485 11.1119 6.04649C10.8139 6.34447 10.4098 6.51188 9.98836 6.51188ZM9.98836 3.51568C9.7102 3.51568 9.43828 3.59815 9.20699 3.75268C8.9757 3.9072 8.79541 4.12684 8.68893 4.38381C8.58245 4.64079 8.55456 4.92357 8.60877 5.19639C8.66299 5.46922 8.79688 5.71985 8.99353 5.91659C9.19017 6.11333 9.44073 6.24734 9.71353 6.30169C9.98633 6.35604 10.2691 6.32829 10.5262 6.22193C10.7832 6.11558 11.0029 5.93541 11.1575 5.70419C11.3122 5.47297 11.3948 5.20109 11.3949 4.92293C11.3942 4.55018 11.2457 4.19291 10.9821 3.92939C10.7185 3.66588 10.3611 3.51761 9.98836 3.51706V3.51568Z" fill="url(#paint0_linear_658_7991)"/>
    <path d="M6.33895 12.3146C6.02468 12.3146 5.71748 12.2214 5.45618 12.0468C5.19487 11.8722 4.99122 11.6241 4.87095 11.3337C4.75069 11.0434 4.71922 10.7239 4.78053 10.4157C4.84184 10.1075 4.99317 9.82433 5.21539 9.60211C5.43761 9.37989 5.72073 9.22856 6.02896 9.16725C6.33718 9.10594 6.65667 9.13741 6.94701 9.25767C7.23735 9.37793 7.48551 9.58159 7.66011 9.84289C7.8347 10.1042 7.92789 10.4114 7.92789 10.7257C7.92789 11.1471 7.76049 11.5512 7.4625 11.8492C7.16452 12.1472 6.76036 12.3146 6.33895 12.3146ZM6.33895 9.31841C6.06081 9.31855 5.78896 9.40114 5.55776 9.55575C5.32656 9.71036 5.14638 9.93004 5.04001 10.187C4.93364 10.444 4.90584 10.7268 4.96014 10.9996C5.01443 11.2723 5.14838 11.5229 5.34505 11.7196C5.54172 11.9162 5.79228 12.0502 6.06506 12.1045C6.33784 12.1588 6.6206 12.131 6.87759 12.0246C7.13457 11.9182 7.35426 11.7381 7.50886 11.5069C7.66347 11.2756 7.74606 11.0038 7.7462 10.7257C7.74602 10.3524 7.59772 9.99453 7.33387 9.73054C7.07001 9.46656 6.71218 9.31809 6.33895 9.31772V9.31841Z" fill="url(#paint1_linear_658_7991)"/>
    <path d="M13.6358 12.3146C13.3216 12.3146 13.0144 12.2214 12.7531 12.0468C12.4918 11.8722 12.2881 11.6241 12.1678 11.3337C12.0476 11.0434 12.0161 10.7239 12.0774 10.4157C12.1387 10.1075 12.29 9.82433 12.5123 9.60211C12.7345 9.37989 13.0176 9.22856 13.3258 9.16725C13.6341 9.10594 13.9535 9.13741 14.2439 9.25767C14.5342 9.37793 14.7824 9.58159 14.957 9.84289C15.1316 10.1042 15.2248 10.4114 15.2248 10.7257C15.2248 11.1471 15.0574 11.5512 14.7594 11.8492C14.4614 12.1472 14.0572 12.3146 13.6358 12.3146ZM13.6358 9.31841C13.3577 9.31868 13.0859 9.40139 12.8548 9.55608C12.6237 9.71077 12.4436 9.9305 12.3374 10.1875C12.2311 10.4445 12.2034 10.7272 12.2578 11C12.3122 11.2727 12.4462 11.5232 12.6429 11.7198C12.8396 11.9164 13.0901 12.0503 13.3629 12.1045C13.6356 12.1588 13.9184 12.1309 14.1753 12.0245C14.4323 11.9181 14.6519 11.738 14.8065 11.5068C14.9611 11.2756 15.0436 11.0038 15.0438 10.7257C15.0438 10.3523 14.8954 9.99414 14.6314 9.7301C14.3673 9.46606 14.0092 9.31772 13.6358 9.31772V9.31841Z" fill="url(#paint2_linear_658_7991)"/>
    <path d="M9.98836 16.6818C9.6741 16.6818 9.36689 16.5886 9.10559 16.414C8.84429 16.2394 8.64063 15.9913 8.52037 15.7009C8.4001 15.4106 8.36864 15.0911 8.42995 14.7829C8.49126 14.4746 8.64259 14.1915 8.86481 13.9693C9.08702 13.7471 9.37015 13.5957 9.67837 13.5344C9.9866 13.4731 10.3061 13.5046 10.5964 13.6249C10.8868 13.7451 11.1349 13.9488 11.3095 14.2101C11.4841 14.4714 11.5773 14.7786 11.5773 15.0929C11.5764 15.514 11.4087 15.9176 11.1109 16.2154C10.8131 16.5132 10.4095 16.6809 9.98836 16.6818ZM9.98836 13.6849C9.70997 13.6848 9.4378 13.7672 9.20627 13.9218C8.97475 14.0764 8.79427 14.2961 8.68767 14.5533C8.58107 14.8105 8.55315 15.0935 8.60742 15.3665C8.6617 15.6396 8.79574 15.8904 8.99259 16.0872C9.18944 16.2841 9.44025 16.4181 9.7133 16.4724C9.98634 16.5267 10.2694 16.4988 10.5265 16.3922C10.7837 16.2856 11.0035 16.1051 11.158 15.8736C11.3126 15.642 11.3951 15.3699 11.3949 15.0915C11.3944 14.7186 11.246 14.3612 10.9stop-color823 14.0975C10.7187 13.8338 10.3612 13.6855 9.98836 13.6849Z" fill="url(#paint3_linear_658_7991)"/>
    <path d="M13.6354 15.803C14.0284 15.803 14.347 15.4844 14.347 15.0915C14.347 14.6985 14.0284 14.3799 13.6354 14.3799C13.2424 14.3799 12.9238 14.6985 12.9238 15.0915C12.9238 15.4844 13.2424 15.803 13.6354 15.803Z" fill="url(#paint4_linear_658_7991)"/>
    <path d="M6.3395 5.63506C6.73249 5.63506 7.05107 5.31648 7.05107 4.92349C7.05107 4.5305 6.73249 4.21191 6.3395 4.21191C5.94651 4.21191 5.62793 4.5305 5.62793 4.92349C5.62793 5.31648 5.94651 5.63506 6.3395 5.63506Z" fill="url(#paint5_linear_658_7991)"/>
    <path d="M13.6354 5.63506C14.0284 5.63506 14.347 5.31648 14.347 4.92349C14.347 4.5305 14.0284 4.21191 13.6354 4.21191C13.2424 4.21191 12.9238 4.5305 12.9238 4.92349C12.9238 5.31648 13.2424 5.63506 13.6354 5.63506Z" fill="url(#paint6_linear_658_7991)"/>
    <path d="M9.98794 11.4358C10.3809 11.4358 10.6995 11.1173 10.6995 10.7243C10.6995 10.3313 10.3809 10.0127 9.98794 10.0127C9.59495 10.0127 9.27637 10.3313 9.27637 10.7243C9.27637 11.1173 9.59495 11.4358 9.98794 11.4358Z" fill="url(#paint7_linear_658_7991)"/>
    <path d="M6.3395 15.803C6.73249 15.803 7.05107 15.4844 7.05107 15.0915C7.05107 14.6985 6.73249 14.3799 6.3395 14.3799C5.94651 14.3799 5.62793 14.6985 5.62793 15.0915C5.62793 15.4844 5.94651 15.803 6.3395 15.803Z" fill="url(#paint8_linear_658_7991)"/>
    <path d="M9.98754 15.3065L6.16992 10.7359L9.87079 4.85059L10.1036 4.99705L6.50844 10.7131L9.98754 14.8775L13.5295 10.6364L13.7409 10.8126L9.98754 15.3065Z" fill="url(#paint9_linear_658_7991)"/>
    <path d="M9.98794 5.63506C10.3809 5.63506 10.6995 5.31648 10.6995 4.92349C10.6995 4.5305 10.3809 4.21191 9.98794 4.21191C9.59495 4.21191 9.27637 4.5305 9.27637 4.92349C9.27637 5.31648 9.59495 5.63506 9.98794 5.63506Z" fill="url(#paint10_linear_658_7991)"/>
    <path d="M6.3395 11.4358C6.73249 11.4358 7.05107 11.1173 7.05107 10.7243C7.05107 10.3313 6.73249 10.0127 6.3395 10.0127C5.94651 10.0127 5.62793 10.3313 5.62793 10.7243C5.62793 11.1173 5.94651 11.4358 6.3395 11.4358Z" fill="url(#paint11_linear_658_7991)"/>
    <path d="M13.6354 11.4358C14.0284 11.4358 14.347 11.1173 14.347 10.7243C14.347 10.3313 14.0284 10.0127 13.6354 10.0127C13.2424 10.0127 12.9238 10.3313 12.9238 10.7243C12.9238 11.1173 13.2424 11.4358 13.6354 11.4358Z" fill="url(#paint12_linear_658_7991)"/>
    <path d="M9.98794 15.803C10.3809 15.803 10.6995 15.4844 10.6995 15.0915C10.6995 14.6985 10.3809 14.3799 9.98794 14.3799C9.59495 14.3799 9.27637 14.6985 9.27637 15.0915C9.27637 15.4844 9.59495 15.803 9.98794 15.803Z" fill="url(#paint13_linear_658_7991)"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_658_7991" x1="7.56556" y1="2.07181" x2="17.4557" y2="13.7167" gradientUnits="userSpaceOnUse">
    <stop stopColor="#3F8E6F"/>
    <stop offset="1" stopColor="#3638AF"/>
    </linearGradient>
    <linearGradient id="paint1_linear_658_7991" x1="2.5842" y1="6.30149" x2="12.4737" y2="17.9471" gradientUnits="userSpaceOnUse">
    <stop stopColor="#3F8E6F"/>
    <stop offset="1" stopColor="#3638AF"/>
    </linearGradient>
    <linearGradient id="paint2_linear_658_7991" x1="6.8227" y1="2.70218" x2="16.7129" y2="14.3471" gradientUnits="userSpaceOnUse">
    <stop stopColor="#3F8E6F"/>
    <stop offset="1" stopColor="#3638AF"/>
    </linearGradient>
    <linearGradient id="paint3_linear_658_7991" x1="2.54864" y1="6.33223" x2="12.4388" y2="17.9771" gradientUnits="userSpaceOnUse">
    <stop stopColor="#3F8E6F"/>
    <stop offset="1" stopColor="#3638AF"/>
    </linearGradient>
    <linearGradient id="paint4_linear_658_7991" x1="4.66752" y1="4.53186" x2="14.557" y2="16.1775" gradientUnits="userSpaceOnUse">
    <stop stopColor="#3F8E6F"/>
    <stop offset="1" stopColor="#3638AF"/>
    </linearGradient>
    <linearGradient id="paint5_linear_658_7991" x1="5.44624" y1="3.87133" x2="15.3357" y2="15.5162" gradientUnits="userSpaceOnUse">
    <stop stopColor="#3F8E6F"/>
    <stop offset="1" stopColor="#3638AF"/>
    </linearGradient>
    <linearGradient id="paint6_linear_658_7991" x1="9.68445" y1="0.271326" x2="19.5739" y2="11.9169" gradientUnits="userSpaceOnUse">
    <stop stopColor="#3F8E6F"/>
    <stop offset="1" stopColor="#3638AF"/>
    </linearGradient>
    <linearGradient id="paint7_linear_658_7991" x1="4.70296" y1="4.50182" x2="14.5931" y2="16.1467" gradientUnits="userSpaceOnUse">
    <stop stopColor="#3F8E6F"/>
    <stop offset="1" stopColor="#3638AF"/>
    </linearGradient>
    <linearGradient id="paint8_linear_658_7991" x1="0.42931" y1="8.13187" x2="10.3188" y2="19.7775" gradientUnits="userSpaceOnUse">
    <stop stopColor="#3F8E6F"/>
    <stop offset="1" stopColor="#3638AF"/>
    </linearGradient>
    <linearGradient id="paint9_linear_658_7991" x1="7.82726" y1="5.70931" x2="11.7285" y2="14.9943" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7FE7BA"/>
    <stop offset="1" stopColor="#00A0E9"/>
    </linearGradient>
    <linearGradient id="paint10_linear_658_7991" x1="9.94234" y1="4.81571" x2="13.8436" y2="14.1007" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7FE7BA"/>
    <stop offset="1" stopColor="#00A0E9"/>
    </linearGradient>
    <linearGradient id="paint11_linear_658_7991" x1="4.7699" y1="6.98886" x2="8.6711" y2="16.2738" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7FE7BA"/>
    <stop offset="1" stopColor="#00A0E9"/>
    </linearGradient>
    <linearGradient id="paint12_linear_658_7991" x1="10.9708" y1="4.38299" x2="14.872" y2="13.668" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7FE7BA"/>
    <stop offset="1" stopColor="#00A0E9"/>
    </linearGradient>
    <linearGradient id="paint13_linear_658_7991" x1="6.31125" y1="6.34188" x2="10.2125" y2="15.6269" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7FE7BA"/>
    <stop offset="1" stopColor="#00A0E9"/>
    </linearGradient>
    <clipPath id="clip0_658_7991">
    <rect width="10.5009" height="13.3333" fill="white" transform="translate(4.75 3.33398)"/>
    </clipPath>
    </defs>
    </Svg>
  );
};

export default Icon;
