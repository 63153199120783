import styled from 'styled-components'
import { Box, Text } from '@pancakeswap/uikit'

export const BottomMenuContainer = styled.div`
  height: 88px;
  width: 100%;
  background-color: #2E293D;
`

export const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`

export const MenuItem = styled.div`
  height: 100%;
  width: 20%;
`

export const MenuIcon = styled.img`
  display: block;
  margin: 20px auto 0;
  width: 32px;
`

export const MenuName = styled(Text)<{ active: boolean}>`
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  color: ${({ active, theme }) => ( active ? theme.colors.primary : theme.colors.text)};
  text-align: center;
  font-size: 12px;
`