import styled from 'styled-components'
import { Box, Text } from '@pancakeswap/uikit'

export const WalletInfoContainer = styled.div`
  width: 100%;
  padding: 16px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.32);
  box-shadow: 12px 8px 12px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 12px 16px 24px 0px rgba(0, 0, 0, 0.24);
`

export const WalletInfoHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const WalletInfoHeaderLeft = styled.div`
  width: 100%;
  height: 32px;
  line-height: 32px;
  font-weight: 500;
  font-size: 16px;
`

export const WalletInfoHeaderRight = styled.div`
  width: 136px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
`

export const WalletInfoHeaderIcon = styled.img`
  width: 32px;
  height: 32px;
  display: block;
  cursor: pointer;
`

export const WalletInfoTitle = styled.div`
  width: 100%;
  margin-top: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.76);
  text-align: center;
`

export const WalletInfoValue = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  margin-top: 12px;
`

export const WalletInfoUsd = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 12px;
`

export const MenuName = styled(Text)`
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  font-size: 12px;
`