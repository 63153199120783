import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { LangSelector, ThemeSwitcher, Box } from '@pancakeswap/uikit'
import { useTranslation, languageList } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import { useRouter } from 'next/router'
import UserMenu from './UserMenu'
import { HeaderBarContainer, HeaderBarLang, HeaderBarLeft, HeaderBarRight, HeaderBarUserMenu, NetworkSwitcherContainer, LangSwap } from './HeaderBarStyles'

const HeaderBar = (props) => {
  const { currentLanguage, setLanguage } = useTranslation()
  const { isDark, setTheme } = useTheme()
  if (!isDark) {
    setTheme('dark')
  }
  const router = useRouter()
  const toHome = () => {
    router.push('/')
  }
  return (
    <HeaderBarContainer>
      <HeaderBarLeft
        src="/images/ekey/logo1.png"
        alt="logo"
        onClick={toHome}
      />
      <HeaderBarRight>
        <HeaderBarLang>
          <LangSwap>
            <LangSelector 
              currentLang={currentLanguage.code}
              langs={languageList}
              setLang={setLanguage}
              buttonScale="xs"
              color="textSubtle"
              hideLanguage
            />
          </LangSwap>
        </HeaderBarLang>
        {/* <ThemeSwitcher isDark={isDark} toggleTheme={() => setTheme(isDark ? 'light' : 'dark')} /> */}
        <NetworkSwitcherContainer>
          <NetworkSwitcher />
        </NetworkSwitcherContainer>
        <HeaderBarUserMenu>
          <UserMenu />
        </HeaderBarUserMenu>
      </HeaderBarRight>
    </HeaderBarContainer>
  )
}

export default HeaderBar
