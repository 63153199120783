import styled from 'styled-components'
import { Box, Flex, Text } from '@pancakeswap/uikit'

export const FooterContainer = styled.div`
  height: auto;
  width: 100%;
  display: block;
  min-height: 150px;
  border-top: 1px solid #2E293D;
  ${({ theme }) => theme.mediaQueries.xl} {
    display: flex;
  }
`

export const FooterLeft = styled(Flex)`
  min-width: 200px;
  padding-top: 35px;
  overflow: hidden;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.xl} {
    padding-top: 0;
  }
`

export const LogoImg = styled.img`
  display: block;
  width: 38px;
  height: 48px;
`

export const FooterMiddle = styled(Flex)`
  width: 100%;
  padding-top: 35px;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.xl} {
    min-width: 600px;
    padding-top: 0;
  }
`

export const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

export const LinkItem = styled.div`
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  width: 100px;
  margin-top: 35px;
  float: left;
`

export const CopyRight = styled.div`
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #969696;
`

export const FooterRight = styled.div`
  min-width: 200px;
  display: flex;
  justify-content: center;
  padding-top: 35px;
  padding-bottom: 35px;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.xl} {
    padding-top: 0;
    padding-bottom: 0;
  }
`
export const IconImg = styled.img`
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 30px;
`
