import { useTranslation } from '@pancakeswap/localization'
import { WalletModalForEkey } from '@pancakeswap/ui-wallets'
import { Button, ButtonProps } from '@pancakeswap/uikit'
import { createWallets, getDocLink } from 'config/wallet'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { useActiveHandle } from 'hooks/useEagerConnect.bmp.ts'
import { useMemo, useState } from 'react'
import { useConnect } from 'wagmi'
import styled from 'styled-components'
import Trans from './Trans'

const ButtonContainer = styled.div`
  padding-left: 34px;
  padding-right: 30px;
  position: relative;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.primary};
  line-height: 48px;
  border-radius: 24px;
  cursor: pointer;
  ${({ theme }) => theme.mediaQueries.md} {
    height: 48px;
    margin-top: 0px;
    padding-left: 44px;
    padding-right: 40px;
  }
`

const WalletIcon = styled.img`
  position: absolute;
  left: 8px;
  top: 5px;
  display: block;
  width: 24px;
  ${({ theme }) => theme.mediaQueries.md} {
    left: 14px;
    top: 11px;
  }
`

const DropDowxIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 15px;
  display: block;
  width: 13px;
  ${({ theme }) => theme.mediaQueries.md} {
    right: 20px;
    top: 20px;
  }
`

const ButtonText = styled.div`
  display: none;
  ${({ theme }) => theme.mediaQueries.md} {
    display: block;
  }
`

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const handleActive = useActiveHandle()
  const { login } = useAuth()
  const {
    t,
    currentLanguage: { code },
  } = useTranslation()
  const { connectAsync } = useConnect()
  const { chainId } = useActiveChainId()
  const [open, setOpen] = useState(false)

  const docLink = useMemo(() => getDocLink(code), [code])

  const handleClick = () => {
    if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
      handleActive()
    } else {
      setOpen(true)
    }
  }

  const wallets = useMemo(() => createWallets(chainId, connectAsync), [chainId, connectAsync])

  return (
    <>
      <ButtonContainer onClick={handleClick}>
        <WalletIcon
          src="/images/ekey/wallet.svg"
          alt="WalletIcon"
        />
        <ButtonText>
          {children || <Trans>Connect Wallet</Trans>}
        </ButtonText>
        <DropDowxIcon
          src="/images/ekey/dropdown.svg"
          alt="DropDowxIcon"
        />
      </ButtonContainer>
      <WalletModalForEkey
        docText={t('Learn How to Connect')}
        docLink={docLink}
        isOpen={open}
        wallets={wallets}
        login={login}
        onDismiss={() => setOpen(false)}
      />
    </>
  )
}

export default ConnectWalletButton
