import styled from 'styled-components'
import { Box, Text } from '@pancakeswap/uikit'

export const LeftMenuContainer = styled.div`
  height: 100%;
  width: 100%;
`

export const LogoContainer = styled(Box)`
  width: 100%;
  height: 90px;
  overflow: hidden;
  cursor: pointer;
  background-image: url('/images/ekey/divider.svg');
  background-repeat: no-repeat;
  background-size: 95% 1px;
  background-position: bottom center;
`

export const LogoImg = styled.img`
  margin: 25px auto 0;
  display: block;
  width: 120px;
`

export const Menu = styled.div`
  width: 100%;
`

export const MenItem = styled.div<{ isHot: boolean; active: boolean; iconActive?: string; iconDisabled?: string }>`
  height: 96px;
  line-height: 96px;
  cursor: pointer;
  position: relative;
  display: flex;
  background-repeat: no-repeat;
  background-size: 60% 1px;
  background-position: bottom center;

  &:hover {
    .menu-name {
      color: ${({ isHot, theme }) => (isHot ? theme.colors.primary : '#676472')};
    }

    .menu-icon {
      background-image: ${({ isHot, active, iconActive, iconDisabled }) =>
        isHot ? `url(${iconActive})` : `url(${iconDisabled})`};
    }
  }
`

export const MenDot = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: ${({ theme }) => `0px 0px 4px ${theme.colors.secondary}`};
  position: absolute;
  left: -6px;
  top: 42px;
  border-radius: 6px;
`

export const MenuIcon = styled.div<{ active: boolean; iconActive?: string; iconDisabled?: string }>`
  display: block;
  width: 32px;
  height: 32px;
  margin-left: 20px;
  margin-top: 32px;
  background-image: ${({ active, iconActive, iconDisabled }) =>
    active ? `url(${iconActive})` : `url(${iconDisabled})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  filter: ${({ active }) => (active ? 'drop-shadow(0px 6px 4px rgba(115,87,255,0.36))' : undefined)};
`

export const MenuName = styled.div<{ active?: boolean; isHot?: boolean }>`
  line-height: 96px;
  color: ${({ active, theme }) => (active ? theme.colors.primary : '#676472')};
  font-size: 16px;
  width: 90px;
  margin-left: 10px;
`

export const MenuHot = styled.img`
  margin-top: 38px;
  margin-left: 16px;
  display: block;
  width: 16px;
  height: 20px;
`

export const MenuDisabled = styled.div`
  width: 44px;
  height: 16px;
  background-color: #e2dcff;
  opacity: 0.4;
  border-radius: 8px;
  font-size: 10px;
  margin-top: 42px;
  line-height: 16px;
  text-align: center;
  color: #7357ff;
`
