export const AddressConfig = {
  // prod env
  ekey: '0x6099a25967257C857811dEA9B501eB804b87dA89',
  wkey: '0x789EE6745Bf989175bd39A729e3Ee38DEB020158',
  ltkey: '0x2c2f3AF3166f1c80c48E4AEeCbDA5663a83D7CAB',
  passcard: '0x57fc359B6D43ee5Ae457F13D302D3783be80C85a',
  predictionETH: '0x4d63f9643CE372a73f46c762CAD689d55a75F08C',
  lottery: '0x615F006cD10F8a77C13872BF4614F1AE35645016',

  oracleETH: '0x639fe6ab55c921f74e7fac1ee960c0b6293ba612',
}

export const predictionConfig = {
  maxReturnRate: 2,
}

export const GraphConfig = {
  // prod env
  predictionETH: 'https://api.studio.thegraph.com/query/46592/prediction-eth/v0.0.3',
  lottery: 'https://api.studio.thegraph.com/query/46592/lottery/v0.0.1',
  passcard: 'https://api.studio.thegraph.com/query/46592/passcard/v0.0.1',
}
