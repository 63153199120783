import styled from 'styled-components'

export const HeaderBarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 21px;
  background-color: #110C22;
  border-bottom: 1px solid #2E293D;
`

export const HeaderBarLeft = styled.img`
  display: block;
  width: 120px;
  height: 40px;
  margin-left: 16px;
`

export const HeaderBarRight = styled.div`
  display: flex;
  justify-content: right;
`

export const HeaderBarLang = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.07);
  margin-right: 12px;

  ${({ theme }) => theme.mediaQueries.md} {
    height: 48px;
    width: 48px;
    margin-right: 24px;
  }
`

export const LangSwap = styled.div`
  position: relative;
  top: 8px;
  left: -2px;

  ${({ theme }) => theme.mediaQueries.md} {
    top: 14px;
    left: 4px;
  }
`

export const NetworkSwitcherContainer = styled.div`
  height: 36px;
  ${({ theme }) => theme.mediaQueries.md} {
    height: 48px;
  }
`

export const HeaderBarUserMenu = styled.div`
  margin-right: 16px;
  margin-left: 10px;
  height: 36px;
  ${({ theme }) => theme.mediaQueries.md} {
    margin-left: 24px;
    height: 48px;
  }
`