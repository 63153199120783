
import { useTranslation } from '@pancakeswap/localization'
import { Link } from '@pancakeswap/uikit'
import { FooterContainer, FooterLeft, FooterMiddle, FooterRight, LogoImg, LinkItem, IconImg, LinkContainer, CopyRight } from './FooterStyles'

const LeftMenu = (props) => {
  const { t } = useTranslation()
  const linkList = [
    {
      name: t('Prediction1'),
      href: '/prediction'
    },
    {
      name: t('Prediction2'),
      href: '/lottery',
    },
    {
      name: t('Prediction3'),
      href: '/lottery',
    },
    {
      name: t('Prediction4'),
      href: '/lottery',
    },
    {
      name: t('Prediction5'),
      href: '/lottery',
    },
  ]
  const iconList = [
    // {
    //   icon: '/images/ekey/Facebook1.png',
    //   href: '/prediction'
    // },
    {
      icon: '/images/ekey/Facebook2.png',
      href: 'https://linktr.ee/etherkeylabs',
    },
    {
      icon: '/images/ekey/Facebook3.png',
      href: 'https://twitter.com/EtherKEYLabs',
    },
    {
      icon: '/images/ekey/Facebook4.png',
      href: 'https://www.youtube.com/@EtherKEYLab',
    }
  ]
  return (
    <FooterContainer>
      <FooterLeft>
        <LogoImg
          src="/images/ekey/logo.png"
          alt="logo"
          width="92px"
        />
      </FooterLeft>
      <FooterMiddle>
        {/* <LinkContainer>
          {
            linkList.map((item) => {
              return <LinkItem
                key={item.name}
              >
                { item.name }
              </LinkItem>
            })
          }
        </LinkContainer> */}
        <CopyRight>
          {t('© 2023 ETHER KEY')}
        </CopyRight>
      </FooterMiddle>
      <FooterRight>
        {
          iconList.map((item) => {
            return (
              <Link
                key={item.icon}
                external
                href={item.href}
              >
                <IconImg
                  src={item.icon}
                />
              </Link>
            )
          })
        }
      </FooterRight>
    </FooterContainer>
  )
}

export default LeftMenu
