import { useRouter } from 'next/router'
import { Text, Box, Image } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import {
  LeftMenuContainer,
  LogoContainer,
  LogoImg,
  Menu,
  MenItem,
  MenuName,
  MenDot,
  MenuIcon,
  MenuHot,
  MenuDisabled,
} from './LeftMenuStyles'
import { menuConfig } from './config'

const LeftMenu = (props) => {
  const router = useRouter()
  const { t } = useTranslation()
  let menuData = menuConfig(t)
  // 后期状态抽出
  menuData = menuData.map((item) => {
    if (window.location.pathname.indexOf(item.href) === 0) {
      // eslint-disable-next-line no-param-reassign
      item.active = true
    }
    return item
  })
  const handleMenuClick = (item) => {
    if (item.href) {
      router.push(`${item.href}`)
    }
  }
  const toHome = () => {
    router.push('/')
  }
  return (
    <LeftMenuContainer>
      <LogoContainer>
        <LogoImg src="/images/ekey/logo1.png" alt="logo" onClick={toHome} />
      </LogoContainer>
      <Menu>
        {menuData.map((item) => {
          return (
            <MenItem key={item.name} onClick={() => handleMenuClick(item)} isHot={item.isHot} active={item.active} iconActive={item.iconActive} iconDisabled={item.iconDisabled}>
              {item.active && <MenDot />}
              <MenuIcon  className='menu-icon' active={item.active} iconActive={item.iconActive} iconDisabled={item.iconDisabled} />
              <MenuName className='menu-name' active={item.active} isHot={item.isHot}>
                {item.name}
              </MenuName>
              {item.isHot && <MenuHot src="/images/ekey/fire.png" alt="logo" />}
              {!item.isHot && <MenuDisabled>SOON</MenuDisabled>}
            </MenItem>
          )
        })}
      </Menu>
    </LeftMenuContainer>
  )
}

export default LeftMenu
