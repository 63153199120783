import { useRouter } from 'next/router'
import { useTranslation } from '@pancakeswap/localization'
import { BottomMenuContainer, MenuName, Menu, MenuItem, MenuIcon } from './BottomMenuStyles'
import { menuConfig } from './config'

const LeftMenu = (props) => {
  const router = useRouter()
  const { t } = useTranslation()
  let menuData = menuConfig(t)
  // 后期状态抽出
  menuData = menuData.map(item => {
    if (window.location.pathname === item.href) {
      item.active = true
    }
    return item
  })
  const handleMenuClick = (item) => {
    if (item.href) {
      router.push(`${item.href}`)
    }
  }
  return (
    <BottomMenuContainer>
      <Menu>
        {
          menuData.map((item) => {
            return <MenuItem
              key={item.name}
              onClick={() => handleMenuClick(item)}
            >
              <MenuIcon
                src={ item.active ? item.iconActive: item.iconDisabled }
                alt="logo"
              />
              <MenuName active={item.active}>{item.name}</MenuName>
            </MenuItem>
          })
        }
      </Menu>
    </BottomMenuContainer>
  )
}

export default LeftMenu
